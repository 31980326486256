<div class="agreement">
  <div class="agreement__wrapper">
    <div class="agreement__logo">
      <img class="logo" src="/assets/images/112-logo.svg">
    </div>
    <div class="agreement__title">
      <span class="agreement__title-text">
        112 – Экстренная помощь
      </span>
      <span class="agreement__title-text small">
       Мобильное приложение
      </span>
      <div class="agreement__title-button">
        <button class="agreement__title-button-btn"
                type="button"
                routerLink=""

        >На главную</button>
      </div>
    </div>
    <div class="agreement__info">
      <span class="agreement__info-title">
         Пользовательское соглашение для функционала «Карантин – Социальный мониторинг» в мобильном приложении «112 – Экстренная помощь» для мобильных операционных систем Android и Apple iOS.
      </span>
      <span class="agreement__info-text">
В соответствии с п. 4 ст. 9, ст. 11 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» <b>даю
        согласие </b>
        на обработку следующих моих персональных данных:
      </span>
      <span class="agreement__info-text">
а) фамилия, имя, отчество (при наличии), пол, дата и место рождения, тип, серия и номер основного документа,
        удостоверяющего личность, сведения о дате выдачи указанного документа и выдавшем его органе, адрес
        регистрации по месту жительства (пребывания), страховой номер индивидуального лицевого счета (СНИЛС),
        контактные данные (номер абонентского устройства подвижной радиотелефонной связи, адрес электронной почты),
        иные сведения, установленные нормативными правовыми актами Российской Федерации и Курской области.
      </span>
      <span class="agreement__info-text">
б) биометрических персональных данных (цифровое фотографическое изображение лица), фото основного документа, удостоверяющего личность (паспорта).
   </span>
      <span class="agreement__info-text">Даю согласие на обработку персональных данных, указанных в настоящем согласии (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу в пределах Российской Федерации (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение) в информационной системе ИС «112 - Экстренная помощь», обеспечивающей функционал «Карантин - Социальный мониторинг» в  мобильном приложении «112 - Экстренная помощь», в целях контроля за перемещением гражданина, находящегося в режиме изоляции с использованием указанных систем:
   </span>
      <span class="agreement__info-text">
1) Комитет региональной безопасности Курской области - оператору и администратору ИС «112 - Экстренная помощь» (функционал «Карантин - Социальный мониторинг) (305004, г. Курск, ул. Челюскинцев 28а),
      </span>
      <span class="agreement__info-text">
2) Комитет здравоохранения Курской области (305000, г. Курск, Красная площадь, 6),
      </span>
      <span class="agreement__info-text">
3) Областное казенное учреждение «Центр обеспечения выполнения полномочий в области гражданской обороны, защиты населения и территории от чрезвычайных ситуаций Курской области» (305000 г. Курск, ул. Челюскинцев, 28 а).</span>
      <span class="agreement__info-text">Соглашаюсь с тем, что указанные юридические лица вправе осуществлять автоматизированную обработку персональных данных, указанных в настоящем Согласии, или их обработку без использования средств автоматизации, в том числе с передачей по каналам связи, а также вправе поручить обработку моих персональных данных другому лицу (лицам).
Согласие действует со дня его подписания до дня окончания режима изоляции по решению лечащего врача.
Согласие на обработку моих персональных данных у каждого из операторов может быть отозвано путем личного обращения или направления письменного обращения, что будет являться фактом неиспользования функционала «Карантин - Социальный мониторинг» в мобильном приложении «112 – Экстренная помощь», о последствиях которого я проинформирован.
      </span>

    </div>
  </div>
</div>

