<div class="main-container">
  <span class="main-container__text">
Комитет региональной безопасности Курской области
  </span>
  <img class="logo" src="/assets/images/112-logo.svg">
  <h1>112 – Экстренная помощь</h1>
  <h2>Мобильное приложение</h2>
  <button class="app-button app-store"
          type="button"
          (click)="goTo('https://apps.apple.com/ru/app/id1499805544')"
  ></button>
  <button class="app-button google-play"
          type="button"
          (click)="goTo('https://play.google.com/store/apps/details?id=com.octopod.service112')"
  ></button>
</div>
<div class="picture">
  <div class="picture__wrapper">
    <span class="picture__title">Разъяснения по использованию модуля COVID-19 в мобильном приложении
      «112 – Экстренная помощь»
    </span>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">1</span>
        <span class="picture__item-text-content">Пользователь скачивает приложение <span
          class="bold">112-Экстренная
          помощь</span> в Apple
          Play
          или Google Play.</span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-01@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">2</span>
        <span class="picture__item-text-content">При первом входе пользователь видит приветственные экраны,
          на которых должен дать <span class="bold">разрешение на отслеживание местоположения и разрешить push-уведомления.</span></span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-02@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">3</span>
        <span class="picture__item-text-content">Регион определяется автоматически, если была разрешена геолокация.
          Пользователь должен нажать кнопку <span class="bold">Далее.</span>
        </span>
        <span class="picture__item-text-content">
                      Если регион не определился, пользователю необходимо выбрать регион вручную.
        </span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-03@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">4</span>
        <span class="picture__item-text-content">
          Пользователь регистрируется по номеру телефона и подтверждает его с помощью кода из СМС.
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-04@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">5</span>
        <span class="picture__item-text-content">
         Заполняет имя и год рождения, медицинские данные и экстренные контакты.
        </span>
        <span class="picture__item-text-content">
          <span class="italic">На данных экранах все поля <span
            class="bold">не</span> обязательны для заполнения.</span>
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-05@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">6</span>
        <span class="picture__item-text-content">
        Добавляет экстренные контакты и нажимает кнопку <span class="bold">Далее.</span>
        </span>
        <span class="picture__item-text-content">
          <span class="italic">Шаг можно пропустить.</span>
        </span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/06-1@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">7</span>
        <span class="picture__item-text-content">
        После регистрации и при повторных входах в приложение пользователь попадает в раздел COVID-19, где должен
          нажать кнопку <span class="bold">Я самоизолирован</span>, чтобы включить режим самоизоляции.
        </span>
        <span class="picture__item-text-title margin">8</span>
        <span class="picture__item-text-content">
          Пользователь попадает в анкету, где должен заполнить все поля:
        </span>
        <ul>
          <li>
            сфотографировать себя, чтобы было видно лицо,
          </li>
          <li>
            сфотографировать главный разворот паспорта,
          </li>
          <li>
            заполнить поля ФИО и дату рождения,
          </li>
          <li> ‘прочитать и поставить галочку «Принимаю условия обработки персональных данных».
          </li>
        </ul>
        <span class="picture__item-text-content italic">
          Все поля <span class="bold">обязательны</span> для заполнения.
        </span>
        <span class="picture__item-text-content italic">
           После заполнения полей нажать кнопку Далее.
        </span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-07@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">9</span>
        <span class="picture__item-text-content">
          На экране <span class="bold">Место самоизоляции</span> пользователь должен максимально точно указать точку на карте и нажать кнопку
          <span class="bold">Далее.</span>
        </span>
        <span class="picture__item-text-content">
          По умолчанию карта открывается на текущем местоположении.
        </span>
        <span class="picture__item-text-title margin">10</span>
        <span class="picture__item-text-content">
          На экране <span class="bold">Адрес</span> пользователь может отредактировать и уточнить определившийся адрес, после чего
          нажать кнопку <span class="bold">Далее</span>.
        </span>
      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-09@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">11</span>
        <span class="picture__item-text-content">
          Пользователь перешел в режим «Самоизоляция» и может свернуть приложение.
        </span>
        <span class="picture__item-text-content">
            Если пользователь прошел проверку (подтверждено наличие человека в списках заболевших),
          его местоположение начинает отслеживаться и ему начинают приходить контрольные уведомления о здоровье.
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-11@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">12</span>
        <span class="picture__item-text-content">
         Если пользователь не найден в списках заболевших, ему приходит уведомление о том, что для начала режима самоизоляции ему необходимо дождаться подтверждения.
        </span>
        <span class="picture__item-text-content">
          После подтверждения пользователь получит повторное уведомление о том что режим самоизоляции включен.
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-12@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">13</span>
        <span class="picture__item-text-content">
          Подтвержденный пользователь в разное время в течение дня (с 10:00 до 21:00) получает уведомление с просьбой
          заполнить анкету о самочувствии и прислать фотографию.
        </span>
        <span class="picture__item-text-content">
          В течение 30 минут пользователь должен открыть приложение, <span class="bold">заполнить анкету, сделать
          селфи</span> и нажать
          кнопку <span class="bold">Отправить.</span>
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-13@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">14</span>
        <span class="picture__item-text-content">
          В фоновом режиме приложение выполняет регулярную проверку местоположения пользователя.
          Если пользователь покинул место самоизоляции, ему отправляется уведомление о необходимости
          вернуться как можно скорее. Одновоременно формируется соответствующая карточка в систему-112.
        </span>
        <span class="picture__item-text-title margin">15</span>
        <span class="picture__item-text-content">
          Если при фоновой проверке местоположения телефон не
          присылает координаты (выключен, отключен от сети или для
          приложения запрещен доступ к геолокации),
          пользователю отправляется уведомление о необходимости проверить
          доступ в интернет и необходимое разрешение для
          продолжения корректной работы режима самоизоляции.
        </span>


      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-14@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title">16</span>
        <span class="picture__item-text-content">
          В любое время оператор может сам позвонить пользователю и попросить его прислать селфи.
          Пользователь должен зайти в приложение в раздел COVID-19, нажать кнопку «Сделать селфи»,
          сфотографироваться и отправить фото. Данное фото с координатами придет карточкой оператору.
        </span>

      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-16@3x.png" alt="instr">
      </div>
    </div>
    <div class="picture__item">
      <div class="picture__item-text">
        <span class="picture__item-text-title margin">17</span>
        <span class="picture__item-text-content">
          Если согласно спискам Управления Роспотребнадзор и Комитета Здравоохранения по г.Курск
          самоизоляция пользователя закончится, ему автоматически придет уведомление о завершении срока.
          Пользователю больше не будут приходить контрольные уведомления, его местоположение не будет отслеживаться и отправленные
          пользователем селфи не будут поступать операторам.
        </span>
        <span class="picture__item-text-content">
          Кнопка «Завершить самоизоляцию» станет доступной после выписки пользователя, но не ранее 14 дней с момента регистрации в приложении.
        </span>


      </div>
      <div class="picture__item-image">
        <img src="../assets/images/instr-17@3x.png" alt="instr">
      </div>
    </div>
  </div>
</div>


<div class="main-container bottom-mail-wrap">
  <label class="bottom-mail-label">По вопросам технической поддержки обращайтесь:
    <button class="bottom-mail-label-btn" ngxClipboard [cbContent]="'support112@octopod.ru'"
            matTooltip="Нажмите на почту, чтобы скопировать">
      <a class="mail-link" href="mailto:support112@octopod.ru">support112@octopod.ru</a>
    </button>
  </label>
</div>
