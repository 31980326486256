<div class="agreement">
  <div class="agreement__wrapper">
    <div class="agreement__logo">
      <img class="logo" src="/assets/images/112-logo.svg">
    </div>
    <div class="agreement__title">
      <span class="agreement__title-text">
        112 – Экстренная помощь
      </span>
      <span class="agreement__title-text small">
       Мобильное приложение
      </span>
      <div class="agreement__title-button">
        <button class="agreement__title-button-btn"
                type="button"
                routerLink=""
        >На главную</button>
      </div>
    </div>
    <div class="agreement__info">
      <span class="agreement__info-title">
           Пользовательское соглашение мобильного приложения «112 – Экстренная помощь»
        для мобильных операционных систем Android и Apple iOS.
      </span>
      <span class="agreement__info-text">
        1. Общие положения
      </span>
      <span class="agreement__info-text">
        1.1. Настоящее Пользовательское соглашение (далее - «Соглашение») регламентирует отношения между Обществом с ограниченной ответственностью «Октопод Профессиональные Сервисы», ОГРН 1127847663130 (далее - «Компания»), и дееспособным физическим лицом, надлежащим образом, присоединившимся к настоящему Соглашению для использования мобильного приложения «112 - Экстренная помощь» (далее - «Пользователь»).
      </span>
      <span class="agreement__info-text">
       1.2. Мобильное приложение «112 - Экстренная помощь» (далее - «Приложение») является программным обеспечением, доступным Пользователю через Магазин приложений на условиях полного соблюдения Соглашения, представляющей собой приложение, предназначенное для оповещений Пользователей об экстренных и не экстренных ситуациях на территории Региона, для экстренной передачи данных Пользователя в Систему 112 Региона, предоставления справочной информации по местоположению различного рода объектов, предоставления (по предварительному согласию Пользователя) возможности отслеживания местоположения Пользователя другим пользователям Приложения, а также предоставления справочной информации первой помощи и рекомендаций к действиям в случае экстренных ситуаций, разработанное для мобильных устройств, работающих под управлением операционных систем Android и Apple iOS. Исключительные права на Приложение принадлежат Компании.
      </span>
      <span class="agreement__info-text">
       1.3. Условия настоящего Соглашения квалифицируются как публичная оферта по смыслу части 2 статьи 437 Гражданского кодекса Российской Федерации – предложением заключить с Компанией договор присоединения в соответствии со статьей 428, п. 5 ст. 1286 Гражданского кодекса Российской Федерации, согласно которым Компания предоставляет Пользователю безвозмездный доступ к Приложению на условиях настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
       1.4. Настоящее Соглашение составлено в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации.
      </span>
      <span class="agreement__info-text">
       1.5. Соглашаясь с условиями настоящего Соглашения, Пользователь подтверждает свою правоспособность и свою дееспособность, подтверждает достоверность своих данных и принимает на себя всю ответственность за их точность, полноту и достоверность.
      </span>
      <span class="agreement__info-text">
       1.6. По всем вопросам и претензиям Пользователи могут обращаться по адресу support112@octopod.ru.
      </span>
      <span class="agreement__info-text">
        2. Термины, используемые в целях настоящего Соглашения
      </span>
      <span class="agreement__info-text">
        2.1. Оператор, Компания – Общество с ограниченной ответственностью «Октопод Профессиональные Сервисы».
      </span>
      <span class="agreement__info-text">
       2.2. Пользователь – физическое лицо, имеющее доступ к Сервису посредством сети Интернет, совершившее Акцепт Соглашения.
      </span>
      <span class="agreement__info-text">
        2.3. Приложение – программное обеспечение и интерфейс, соответствующие требованиям определенной платформы, позволяющее автономно пользоваться Услугами в рамках данной платформы.





</span>
      <span class="agreement__info-text">
        2.4. Платформа – аппаратно-программный комплекс, обеспечивающий предоставление Услуг.
      </span>
      <span class="agreement__info-text">
       2.5. SMS-подтверждение – цифровой код, получаемый Пользователем в виде SMS сообщения, для завершения процедуры регистрации в Приложении.
      </span>
      <span class="agreement__info-text">
      2.6. Регион – Субъект Российской Федерации, в котором определяется территориальное местоположение Пользователя (с его согласия).
      </span>
      <span class="agreement__info-text">
      2.7. Регистрация – процедура предоставления Пользователем сведений, необходимых для его идентификации в Сервисе, в соответствии с регистрационной формой.
      </span>
      <span class="agreement__info-text">
     2.8. Услуги – услуги по предоставлению доступа к возможности получения оповещений Пользователей об экстренных и не экстренных ситуациях на территории Региона, к экстренной передачи данных Пользователя в Систему 112 Региона, предоставления справочной информации по местоположению различного рода объектов, предоставления (по предварительному согласию Пользователя) возможности отслеживания местоположения Пользователя другим пользователям Приложения, а также предоставления справочной информации первой помощи и рекомендаций к действиям в случае экстренных ситуаций посредством Приложения, оказываемые Компанией.
      </span>
      <span class="agreement__info-text">
       2.9. Учетная запись – запись, содержащая сведения, необходимые для идентификации Пользователя при предоставлении доступа к Приложению. К такой записи, в том числе, относятся имя, используемое Пользователем для доступа к Приложению (логин) и пароль (или другие аналогичные средства аутентификации).
      </span>
      <span class="agreement__info-text">
      2.10. ЭОС – экстренные оперативные службы, к которым относятся Служба пожарной охраны, Служба реагирования в чрезвычайных ситуациях, Полиция, Служба скорой медицинской помощи, Аварийная служба газовой сети, Служба "Антитеррор".
      </span>
      <span class="agreement__info-text">
3. Предмет Соглашения
 </span>
      <span class="agreement__info-text">
3.1. Компания предоставляет Пользователям доступ к Услугам посредством Приложения, при условии соблюдения Пользователями условий настоящего Соглашения и условий оказания Услуг.
      </span>
      <span class="agreement__info-text">
3.2. Для использования Приложения Пользователь обязан в порядке, установленном настоящим Соглашением, подтвердить, что прочитал, понял, согласен соблюдать настоящее Соглашение, и присоединиться к настоящему Соглашению в целом путём совершения Акцепта Соглашения. С момента Акцепта Соглашения Пользователем, настоящее Соглашение считается заключённым с Пользователем, и Пользователь приобретает права и обязанности, предусмотренные настоящим Соглашением.
      </span>
      <span class="agreement__info-text">
3.3. Изменение настоящего Соглашения совершается путём размещения Компанией изменений к настоящему Соглашению или новой редакции настоящего Соглашения в Персональном разделе Приложения не позднее, чем за10 (Десять) календарных дней до дня вступления изменений к настоящему Соглашению или новой редакции настоящего Соглашения в силу. Если Пользователь не согласен пользоваться Приложением на условиях новой редакции настоящего Соглашения, то Пользователь вправе отказаться от исполнения настоящего Соглашения с Компанией, удалив свою Учетную запись. Настоящее Соглашение в этом случае считается прекращённым с момента удаления Пользователем своей Учётной записи, и с этого момента Пользователь утрачивает право пользования Приложением.
      </span>
      <span class="agreement__info-text">
3.4. Если Пользователь до дня вступления в силу изменений настоящего Соглашения или новой редакции настоящего Соглашения (и то и другое в дальнейшем, если применимо, именуется «новой редакцией Соглашения») не удалил свою Учётную запись, то Пользователь тем самым подтверждает, что прочитал, понял, согласен соблюдать новую редакцию настоящего Соглашения и присоединяется к новой редакции настоящего Соглашения в целом со дня вступления в силу указанных изменений к настоящему Соглашению или новой редакции настоящего Соглашения. Настоящее Соглашение в этом случае считается изменённым со дня вступления в силу изменений к настоящему Соглашению или новой редакции настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
3.5.Пользователь вправе в любой момент отказаться от настоящего Соглашения с Компанией, удалив свою Учетную запись в порядке, установленном п. 6.4.3 настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
3.6. В целях настоящего Соглашения используется местное региональное время (время часовой зоны, в которой расположен Регион Пользователя).
      </span>
      <span class="agreement__info-text">
3.7. Фактический доступ Пользователя к Приложению, возможен только на оборудовании, которое имеет техническую возможность доступа к сети Интернет (на котором установлено необходимое и достаточное программное обеспечение для доступа к сети Интернет, которое имеет фактический доступ к сети Интернет, а также соответствует техническим требованиям, предъявляемым Приложением).
      </span>
      <span class="agreement__info-text">
4. Акцепт Соглашения
      </span>
      <span class="agreement__info-text">
4.1. Акцептом настоящего Соглашения является совершение Пользователем следующих последовательных конклюдентных действий после установки Приложения на мобильное устройство.
      </span>
      <span class="agreement__info-text">
4.1.1. Ознакомление с условиями Соглашения, отображаемого на одном из этапов Регистрации при первичном обращении Пользователя к Приложению.
      </span>
      <span class="agreement__info-text">
4.1.2. Выбор пункта (с проставлением «галочки»), подтверждающего согласие с условиями Соглашения при первом обращении Пользователя к Приложению.
      </span>
      <span class="agreement__info-text">
5. Порядок регистрации
     </span>
      <span class="agreement__info-text">
5.1. Регистрация осуществляется в Приложении посредством ввода в форму регистрации имени, номера мобильного телефона Пользователя и подтверждающего кода, который направляется Пользователю посредством SMS-сообщения на указанный Пользователем номер телефона. Фактическое осуществление Регистрации возможно только на оборудовании, соответствующем требованиям, установленным п. 3.7. настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
5.2. Осуществляя Регистрацию, Пользователь должен следовать инструкциям по Регистрации, содержащимся в регистрационной форме.
      </span>
      <span class="agreement__info-text">
5.3. Регистрация считается завершённой Пользователем с момента получения Компанией надлежащим образом заполненной регистрационной формы.
      </span>
      <span class="agreement__info-text">
5.4. Оператор не отвечает за неполучение направленной Пользователем надлежащим образом заполненной регистрационной формы.
      </span>
      <span class="agreement__info-text">
5.5. Пользователь с момента Регистрации получает возможность Авторизации в Приложении.
      </span>
      <span class="agreement__info-text">
5.6. Пользователь при осуществлении Регистрации соглашается на получение сообщений, от Системы-112 Региона на номер мобильного телефона, добавленный в Учётную запись, и/или на получение push-уведомлений.

      </span>
      <span class="agreement__info-text">
6. Права и обязанности сторон </span>
      <span class="agreement__info-text">
6.1. Компания обязана.

      </span>
      <span class="agreement__info-text">
6.1.1. Обеспечить Пользователю доступ к функционалу Приложения в соответствии с условиями настоящего Соглашения.

      </span>
      <span class="agreement__info-text">
6.1.2. Не разглашать, не передавать информацию третьим лицам о Пользователе и его операциях с Приложением, за исключением случаев, предусмотренных законодательством Российской Федерации.

      </span>
      <span class="agreement__info-text">
6.2. Компания имеет право.

      </span>
      <span class="agreement__info-text">
6.2.1. Заблокировать на любой период или удалить учетную запись в случае нарушения Пользователем условий настоящего Соглашения, Правил модерации сообщений.
      </span>
      <span class="agreement__info-text">
6.2.2. Отказать Пользователю в обработке сообщений в случае нарушения Пользователем своих обязательств.
      </span>
      <span class="agreement__info-text">
6.2.3. Отказать Пользователю в регистрации в Приложении в случае некорректного ввода SMS-подтверждения.
      </span>
      <span class="agreement__info-text">
6.2.4. Вносить изменения в функционал Приложения, а также предоставлять доступ к новым возможностям Приложения с последующим уведомлением Пользователя или без такового.
      </span>
      <span class="agreement__info-text">
6.2.5. В любой момент времени изменить условия данного Соглашения в соответствии с п. 3.3. настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
6.2.6. Проводить профилактические работы по проверке/исправлению Приложения с временным приостановлением доступа Пользователя к Приложению.
      </span>
      <span class="agreement__info-text">
6.2.7. Заблокировать на любой период или удалить учетную запись Пользователя в случае, если Пользователь направил в Приложение недостоверные сведения о происшествии или информацию, квалифицированную как спам.
      </span>
      <span class="agreement__info-text">
6.2.8. Осуществлять с разрешения Пользователя определение территориального местоположения персонального устройства Пользователя по средствам GSM- и WiFi-сетей:
      </span>
      <span class="agreement__info-text">
6.2.8.1. При согласии Пользователя на определение его территориального местоположения, в случае создания заявки или отправки вызова в Службу 112, ЭОС будут направляться данные о местоположении Пользователя, что позволит ЭОС быстрее реагировать на вызовы и более оперативно оказывать помощь.
      </span>
      <span class="agreement__info-text">
6.2.8.2. При согласии Пользователя на определение его территориального местоположения, в случае постановки Пользователя на самоизоляцию в связи с заболеванием COVID-19, Приложение будет запрашивать его местоположение в фоновом режиме для контроля соблюдения режима самоизоляции (по требованию Министерства здравоохранения Региона и Службы 112 Региона).
      </span>
      <span class="agreement__info-text">
6.3. Пользователь обязан.
  </span>
      <span class="agreement__info-text">
6.3.1. Ознакомиться с условиями настоящего Соглашения и соблюдать условия настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
6.3.2. Обеспечить за свой счет доступ в Интернет, настройку и защиту от несанкционированного использования своего мобильного телефона (иного персонального устройства).
      </span>
      <span class="agreement__info-text">
6.3.3. Нести ответственность за содержание информации в сообщениях и свои действия, предпринятые с использованием Приложения.
      </span>
      <span class="agreement__info-text">
6.3.4. Не использовать функционал Приложения для совершения каких-либо действий, противоречащих законодательству Российской Федерации, законодательству Региона, нормативно-правовой базе о системе обеспечения вызова экстренных оперативных служб по единому номеру «112» на территории Региона.
      </span>
      <span class="agreement__info-text">
6.3.5. Не использовать Приложение как средство пропаганды, дискриминации, провокации, продвижения общественных, политических или коммерческих интересов.
      </span>
      <span class="agreement__info-text">
6.3.6. Не размещать материалы, которые не соответствуют целям функционирования Приложения, ущемляют интересы других Пользователей или третьих лиц, или по другим причинам являются нежелательными для размещения в Приложении.
      </span>
      <span class="agreement__info-text">
6.3.7. Не размещать информацию и материалы, прямо или косвенно нарушающие права третьих лиц.
      </span>
      <span class="agreement__info-text">
6.3.8. Не размещать информацию и материалы, содержащие ссылки на сторонние ресурсы и сервисы сети Интернет.
      </span>
      <span class="agreement__info-text">
6.3.9. Не использовать Приложение в целях противоречащих настоящему Соглашению и оказываемым Услугам.
      </span>
      <span class="agreement__info-text">
6.3.10. Не осуществлять обратную разработку, декомпиляцию, дизассемблирование Приложения и произведению любых других действий с исходным кодом Приложения.
      </span>
      <span class="agreement__info-text">
6.4. Пользователь вправе.
      </span>
      <span class="agreement__info-text">
6.4.1. Пользоваться Приложением в соответствии с условиями настоящего Соглашения.
      </span>
      <span class="agreement__info-text">
6.4.2. Средствами Приложения производить действия, доступные Пользователю в приложении.
      </span>
      <span class="agreement__info-text">
6.4.3. В любой момент удалить свою Учетную запись из Приложения, обратившись в службу технической поддержки support112@octopod.ru.
      </span>
      <span class="agreement__info-text">
7. Использование и хранение информации и материалов.
   </span>
      <span class="agreement__info-text">
7.1. Оператор определяет срок хранения и максимальный объем информации и материалов, размещаемых Пользователем в Приложении.
      </span>
      <span class="agreement__info-text">
7.2. Пользователь предоставляет Компании неисключительную лицензию для использования информации и материалов, размещенных Пользователем в Приложении, путем воспроизведения и передачи в экстренные службы (в том числе, на территории Новосибирской области - в ГКУ НСО "Служба 112", в Курской области - в ОКУ "ЦОД ГОЧС Курской области", на территории города федерального значения Севастополь – ГКУ "ЕДДС Севастополя", на территории Камчатского края - ГКП "Единый ситуационно – мониторинговый центр").
      </span>
      <span class="agreement__info-text">
7.3. Пользователь может в любой момент выйти из Приложения. В случае выхода Пользователем из Приложения Компания имеет право сохранять архивные копии информации и материалов, размещенных Пользователем.
      </span>
      <span class="agreement__info-text">
7.4. Вводя данные экстренных контактов, Пользователь дает согласие на получение ими сообщений от Системы-112 Региона.
      </span>
      <span class="agreement__info-text">
8. Ограничения ответственности Компании
 </span>
      <span class="agreement__info-text">
8.1. Приложение предоставляется и используется на условиях «как есть», т.е. без дополнительных гарантий качества, пригодности и т.п. Оператор не принимает на себя обязательств по доработке и поддержке Приложения в течение какого-либо периода времени, не принимает на себя обязательств по устранению ошибок или иных недостатков, а также вправе не отвечать на запросы Пользователя по недостаткам или модификациям Приложения.
      </span>
      <span class="agreement__info-text">
8.2. Пользователь осуществляет добровольное использование Приложения, учитывая все риски. Оператор не принимает на себя обязательств по возмещению любых убытков Пользователя, которые могут возникнуть в связи с использованием Приложения, за исключением случаев, специально оговоренных Соглашением.
      </span>
      <span class="agreement__info-text">
8.3. Оператор не несет ответственности за предоставляемый материал, за материалы, размещаемые в Приложении пользователями, а также не дает гарантий на безукоризненное реагирование на обращение в Систему-112. Для безусловного реагирования на экстренный вызов Пользователю следует воспользоваться вызовом Системы-112 по средствам телефонной связи.
      </span>
      <span class="agreement__info-text">
8.4. Использование Приложения Пользователем не гарантирует оказание ему помощи в экстренной ситуации, если не была осуществлена телефонная связь Пользователя с оператором, обрабатывающим вызовы Системы-112 Региона, в процессе использования Приложения.
      </span>
      <span class="agreement__info-text">
8.5. Учитывая условия настоящего Соглашения, Компания никаким образом не обязана контролировать содержание информации и материалов Пользователей и ни при каких обстоятельствах не несет ответственности за содержание информации и материалов Пользователей и за соответствие их требованиям законодательства, за нарушение авторских, смежных и иных прав, несанкционированное использование товарных знаков, фирменных наименований и логотипов, а также за возможные нарушения прав третьих лиц в связи с размещением информации и материалов средствами Приложения. В случае поступления претензий от третьих лиц, связанных с размещением информации и материалов Пользователя, Пользователь самостоятельно и за свой счет урегулирует указанные претензии.
      </span>
      <span class="agreement__info-text">
8.6. Оператор не несет ответственности за любую информацию, размещенную на порталах и сайтах третьих лиц, к которым Пользователь получил доступ через Приложение, включая, в том числе, любые мнения или утверждения, выраженные на порталах и сайтах третьих лиц или в их материалах.
      </span>
      <span class="agreement__info-text">
8.7. Компания обеспечивает работу Приложения, однако не несет ответственность за его бесперебойную работу, за потерю каких-либо данных, размещенных в Приложении или за причинение любых других убытков, которые возникли или могут возникнуть при пользовании Приложения.
      </span>
      <span class="agreement__info-text">
8.8. Компания не несет ответственности за неисполнение либо ненадлежащее исполнение своих обязательств вследствие сбоев в телекоммуникационных и энергетических сетях, действий вредоносных программ, нарушений функционирования операторов, предоставляющих услуги доступа в Интернет, а также недобросовестных действий третьих лиц, направленных на несанкционированный доступ и(или)выведение из строя программной и (или) аппаратной Платформы Приложения.
      </span>
      <span class="agreement__info-text">
8.9. Компания не несет ответственности за информацию, размещенную Пользователем в графе «Имя» при заполнении раздела экстренных контактов в Приложении.
      </span>
      <span class="agreement__info-text">
9. Заключительные положения.
      </span>
      <span class="agreement__info-text">
9.1. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
      </span>
      <span class="agreement__info-text">
9.2. Данное Соглашение и любые опубликованные изменения к данному Соглашению действуют в полном объеме и остаются в силе в течение всего периода использования Пользователем Приложения.
      </span>
      <span class="agreement__info-text">
9.3. Соглашение действует в течение всего срока действия исключительных прав на Приложение и на всей территории Российской Федерации.
      </span>
      <span class="agreement__info-text">
9.4. Если по тем или иным причинам одна или несколько норм настоящего Соглашения является недействительной или не имеющей юридической силы, это не оказывает влияния на действительность или применимость остальных норм.
      </span>
    </div>
  </div>
</div>
