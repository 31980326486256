import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgreementComponent } from './shared/components/agreement/agreement.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './shared/components/main/main.component';
import { AgreeNovosibComponent } from './shared/components/agree-novosib/agree-novosib.component';
import { AgreeKurskComponent } from './shared/components/agree-kursk/agree-kursk.component';
import { AgreeAnonComponent } from './shared/components/agree-anon/agree-anon.component';

const appRoutes: Routes =[
  { path: '', component: MainComponent},
  { path: 'agreement', component: AgreementComponent},
  { path: 'agreeNovosib', component: AgreeNovosibComponent},
  { path: 'agreeKursk', component: AgreeKurskComponent},
  { path: 'agreeRegion', component: AgreeAnonComponent},

];


@NgModule({
  declarations: [
    AppComponent,
    CarouselComponent,
    AgreementComponent,
    MainComponent,
    AgreeNovosibComponent,
    AgreeKurskComponent,
    AgreeAnonComponent
  ],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    ClipboardModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
